import { ReactNode } from 'react';
import { AutoSizer, ListProps } from 'react-virtualized';

import { mapQuery } from 'polli-commons-fe/utils/query';
import { CosmosChainType } from 'polli-commons-fe/types';
import { TableProps } from 'polli-commons-fe/components/table';
import { addCurrencyLabel } from 'polli-commons-fe/utils/currencies';
import { useTableSort, useAnalyticsEventTracker } from 'polli-commons-fe/hooks';
import {
  formatNumber,
  addPercentageLabel,
} from 'polli-commons-fe/utils/helpers';
import { composeGridTemplateColumns } from 'polli-commons-fe/components/table/helpers';
import { PlaceholderTableRow } from 'polli-commons-fe/components/table/placeholder-table-row';
import {
  Flex,
  Semibold,
  TableRowProps,
  DataFetchWrapper,
} from 'polli-commons-fe/components';
import {
  mapSortableHeaders,
  TableWithSortingProps,
} from 'polli-commons-fe/components/sortable-table';

import { ValidatorInfo } from 'components';
import { GetCosmosValidatorsResponse } from 'store/api/cosmos-validators';
import {
  formatCosmosUnit,
  convertCosmosMicroUnitToUnit,
} from 'utils/currencies';

import * as Styles from './styles';
import { rowsGap, rowHeight } from './config';

const validatorsListTemplate = composeGridTemplateColumns([
  { fr: 2 },
  { fr: 1, repeat: 3 },
  { fr: 2 },
  { fr: 1, repeat: 2 },
]);

const headers: TableWithSortingProps['headers'] = [
  {
    accessor: 'name',
    label: 'Validator',
  },
  {
    label: 'Validator APR',
    accessor: 'validatorAnnualPercentageRate',
  },
  {
    label: 'Network APR',
    accessor: 'networkAnnualPercentageRate',
  },
  {
    label: 'Validator Commission',
    accessor: 'validatorCommissionRate',
  },
  {
    accessor: 'totalStakedTokens',
    label: 'Total Delegated Tokens',
  },
  {
    label: 'Voting Power',
    accessor: 'votingPower',
    info: 'Represents the influence a participant has in the network decision-making process, determined by the amount of cryptocurrency they have staked with validators',
  },
  {
    label: 'Scoring rate',
    accessor: 'scoringRate',
    info: 'Custom indicator that combines factors such as APR, commission rates, and overall voting power to assess their reliability and performance within the network',
  },
];

type ValidatorData = GetCosmosValidatorsResponse['data'][number];

interface ValidatorsTableProps
  extends Omit<ReturnType<typeof useTableSort>, 'setSortState'>,
    TableProps {
  sortingDisabled?: boolean;
  chainType: CosmosChainType;
  additionalTemplate?: string;
  data?: GetCosmosValidatorsResponse;
  additionalHeaders?: TableRowProps['headers'];
  renderTableRow: (props: {
    data: ValidatorData;
    baseRowProps: Pick<TableRowProps, 'values' | 'template'>;
  }) => ReactNode;
}

export const ValidatorsTable = ({
  data,
  sortState,
  chainType,
  emptyTitle,
  handleSorting,
  renderTableRow,
  sortingDisabled,
  additionalTemplate,
  additionalHeaders = [],
  placeholderRowsCount = 9,
  ...validatorsQuery
}: ValidatorsTableProps) => {
  const eventTracker = useAnalyticsEventTracker(
    'Choose validators to delegate'
  );

  const items = data?.data;

  const resultHeaders = [...headers, ...additionalHeaders];
  const resultTemplate = additionalTemplate
    ? `${validatorsListTemplate} ${additionalTemplate}`
    : validatorsListTemplate;

  const rowRenderer: ListProps['rowRenderer'] = ({ key, index, style }) => {
    const currentItem = items?.[index];

    if (!currentItem) {
      return (
        <PlaceholderTableRow
          key={key}
          style={style}
          index={index}
          columnsCount={resultHeaders.length}
        />
      );
    }

    const baseRowProps = {
      template: resultTemplate,
      values: [
        <ValidatorInfo name={currentItem.name} address={currentItem.address} />,
        <Semibold>
          {addPercentageLabel(
            formatNumber(currentItem.validatorAnnualPercentageRate, 2)
          )}
        </Semibold>,
        addPercentageLabel(
          formatNumber(currentItem.networkAnnualPercentageRate, 2)
        ),
        addPercentageLabel(currentItem.validatorCommissionRate),
        addCurrencyLabel(
          formatCosmosUnit(
            convertCosmosMicroUnitToUnit(currentItem.totalStakedTokens)
          ),
          chainType
        ),
        addPercentageLabel(formatNumber(currentItem.votingPower, 2)),
        formatNumber(currentItem.scoringRate, 2),
      ],
    };

    return (
      <Styles.TableRowWrapper key={key} style={style}>
        {renderTableRow({ baseRowProps, data: currentItem })}
      </Styles.TableRowWrapper>
    );
  };

  return (
    <Flex column flex={1}>
      <Styles.HeadersRow
        template={resultTemplate}
        headers={mapSortableHeaders(
          resultHeaders,
          sortState,
          handleSorting,
          eventTracker,
          sortingDisabled
        )}
      />

      <Flex flex={1}>
        <DataFetchWrapper
          {...mapQuery(validatorsQuery)}
          emptyTitle={emptyTitle}
          hasData={Boolean(items && items.length > 0)}
          placeholderElement={Array.from({
            length: placeholderRowsCount,
          }).map((_, index) => (
            <PlaceholderTableRow
              key={index}
              index={index}
              columnsCount={resultHeaders.length}
            />
          ))}
        >
          <AutoSizer>
            {({ width, height }) => (
              <Styles.VirtualizedList
                width={width}
                height={height}
                rowRenderer={rowRenderer}
                rowCount={items?.length ?? 0}
                rowHeight={rowHeight + rowsGap}
              />
            )}
          </AutoSizer>
        </DataFetchWrapper>
      </Flex>
    </Flex>
  );
};
