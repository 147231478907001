import { createApi } from '@reduxjs/toolkit/query/react';

import { cosmosApiQuery } from 'polli-commons-fe/store/query';
import { replaceAllWithMap } from 'polli-commons-fe/utils/string';

import { URL } from 'api/constants';

import { AvailableCompoundsRequest, AvailableCompoundsResponse } from './types';

export const compoundingTagTypes = ['compounding'];

export const compoundingApi = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'compoundingApi',
  tagTypes: compoundingTagTypes,
  endpoints: (build) => ({
    getAvailableCompounds: build.query<
      AvailableCompoundsResponse[],
      AvailableCompoundsRequest
    >({
      providesTags: compoundingTagTypes,
      query: ({ granteeWalletAddress, delegatorWalletAddress }) => ({
        method: 'get',
        url: replaceAllWithMap(
          URL.COMPOUNDING_AVAILABLE_COMPOUNDS_BY_DELEGATOR,
          {
            '{granteeWalletAddress}': granteeWalletAddress,
            '{delegatorWalletAddress}': delegatorWalletAddress,
          }
        ),
      }),
    }),
  }),
});

export const { useGetAvailableCompoundsQuery } = compoundingApi;

export type { AvailableCompoundsRequest, AvailableCompoundsResponse };
