import styled, { css } from 'styled-components';

import { subTitle } from 'polli-commons-fe/styles';

import { Icon } from '../icons';
import { EllipsisText } from '../ellipsis-text';
import { Flex, Caption, LightGrayOpacity, AccentGradientIcon } from '../styles';

export const Container = styled(Flex)<{ $hasError: boolean }>`
  flex: 1;
  padding: 12px 16px;
  border-radius: 40px;
  justify-content: center;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  gap: 16px;

  ${({ theme, $hasError }) => css`
    box-shadow: ${theme.config.numberCard.boxShadow};
    background: ${theme.config.numberCard.background};

    ${theme.responsive.isMobile &&
    css`
      text-align: center;
    `}

    ${$hasError &&
    css`
      min-width: 0;
    `}
  `}
`;

export const Inner = styled(Flex)`
  gap: 8px;
  min-width: 0;
  align-items: center;
`;

export const IconContainer = styled(AccentGradientIcon)`
  width: 32px;
  height: 32px;
`;

export const NumberChange = styled(Caption)<{
  $numberChangeIncreased: boolean;
}>`
  display: flex;
  font-weight: 500;
  align-items: center;

  ${({ theme, $numberChangeIncreased }) => {
    const color = $numberChangeIncreased
      ? theme.colors.success
      : theme.colors.red.primary.main;

    return css`
      color: ${color};

      svg {
        ${$numberChangeIncreased &&
        css`
          transform: rotate(180deg);
        `}

        path {
          fill: ${color};
        }
      }
    `;
  }}
`;

export const Error = styled(EllipsisText)`
  color: ${({ theme }) => theme.colors.red.additional.main};
`;

export const CurrencyLabel = styled(LightGrayOpacity)`
  ${subTitle};
`;

export const Loader = styled(Icon.Loader)`
  margin: 0 auto;
  width: 24px;
  height: 24px;
`;
