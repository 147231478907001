export const accentGradient = Object.freeze({
  blue: '#187CC6',
  green: '#039750',
  yellow: '#ECCD2B',
});

export const accent = Object.freeze({
  secondary: {
    main: 'linear-gradient(65.57deg, rgba(255, 249, 112, 0) -185.9%, rgba(255, 249, 112, 0.4) -92.04%, rgba(143, 201, 42, 0.4) -0.67%, rgba(9, 137, 197, 0.4) 80.57%)',
    hover:
      'linear-gradient(0deg, rgba(4, 19, 1, 0.3) 0%, rgba(4, 19, 1, 0.3) 100%), linear-gradient(226deg, rgba(253, 221, 56, 0.16) 20.01%, rgba(7, 172, 93, 0.16) 54.9%, rgba(35, 134, 206, 0.16) 86.45%)',
  },
  primary: {
    main: `linear-gradient(218deg, ${accentGradient.yellow} 0%, ${accentGradient.green} 52.51%, ${accentGradient.blue} 100%)`,
    hover:
      'linear-gradient(0deg, rgba(251, 251, 251, 0.15) 0%, rgba(251, 251, 251, 0.15) 100%), linear-gradient(218deg, #FDDD38 0%, #07AC5D 52.51%, #2386CE 100%)',
    active:
      'linear-gradient(0deg, rgba(251, 251, 251, 0.25) 0%, rgba(251, 251, 251, 0.25) 100%), linear-gradient(218deg, #FDDD38 0%, #07AC5D 52.51%, #2386CE 100%)',
  },
});

export const black = '#101711' as string;

export const white = Object.freeze({
  primary: {
    main: '#FBFBFB',
    hover: '#BDC1BC',
    active: '#A4AAA3',
  },
  secondary: {
    main: 'rgba(251, 251, 251, 0.1)',
    hover: 'rgba(251, 251, 251, 0.25)',
    active: 'rgba(251, 251, 251, 0.35)',
  },
});

export const grey = Object.freeze({
  primary: {
    main: '#7F867D',
    hover: '#60695E',
    active: '#3F4B3D',
  },
});

export const red = Object.freeze({
  secondary: {
    main: '#EC5757',
  },
  additional: {
    main: '#F16464',
  },
  primary: {
    main: '#E53333',
    hover: '#AD2B26',
    active: '#962821',
  },
});

export const blue = Object.freeze({
  primary: {
    main: '#0978C5',
  },
  secondary: {
    main: '#0978C533',
  },
});

const background = black as string;

const modalBackground = background;

const reward = 'rgba(232, 219, 107, 0.36)';
const lightGrayOpacity = 'rgba(251, 251, 251, 0.5)';
const backgroundDots = 'rgb(245, 245, 105)';

const success = '#04A168';

const darkAccentGradient =
  'linear-gradient(226deg, rgba(236, 205, 43, 0.3) 20.01%, rgba(3, 151, 80, 0.3) 54.9%, rgba(24, 124, 198, 0.3) 86.45%)';
const yellowGradient =
  'linear-gradient(156.04deg, #D58611 18.03%, #F3C44A 87.98%)';

export const DARK_COLORS = Object.freeze({
  red,
  grey,
  blue,
  black,
  white,
  reward,
  accent,
  success,
  background,
  backgroundDots,
  yellowGradient,
  accentGradient,
  modalBackground,
  lightGrayOpacity,
  darkAccentGradient,
});
