import { ReactNode } from 'react';

import { ChainType, CosmosChainType } from 'polli-commons-fe/types';

import { Icon } from 'components/icons';

import {
  Preset,
  WalletProvider,
  WalkthroughStep,
  WalkthroughStepOptions,
} from '.';

export const PresetOptionsTitles: Record<Preset, string> = {
  '25': '25%',
  '50': '50%',
  '75': '75%',
  '100': 'Max',
};

export const ChainTypeTitle: Record<ChainType, string> = {
  LAVA: 'Lava',
  SECRET: 'Secret',
  COSMOS: 'Cosmos',
  ETHEREUM: 'Ethereum',
};

interface WalletProviderConfigBaseProps {
  title: string;
  icon: ReactNode;
}

export const WalletProviderConfig: Record<
  WalletProvider,
  WalletProviderConfigBaseProps
> = {
  leap: {
    title: 'Leap',
    icon: <Icon.Leap />,
  },
  keplr: {
    title: 'Keplr',
    icon: <Icon.Keplr />,
  },
  trezor: {
    title: 'Trezor',
    icon: <Icon.Trezor />,
  },
  cosmostation: {
    title: 'Cosmostation',
    icon: <Icon.Cosmostation />,
  },
  walletConnect: {
    title: 'Wallet Connect',
    icon: <Icon.WalletConnect />,
  },
  metamask: {
    title: 'Metamask',
    icon: <Icon.Metamask width={24} height={24} />,
  },
};

export const ConflictingWalletProviders: Record<
  WalletProvider,
  WalletProvider[]
> = {
  keplr: ['cosmostation', 'leap'],
  cosmostation: ['leap', 'keplr'],
  leap: ['keplr', 'cosmostation'],
  trezor: ['walletConnect', 'metamask'],
  walletConnect: ['trezor', 'metamask'],
  metamask: ['trezor', 'walletConnect'],
};

export const WalkthroughDataAttributeName = 'data-walkthrough-step' as const;

export const WalkthroughDataAttribute = Object.fromEntries(
  WalkthroughStepOptions.map((option) => [
    option,
    { [WalkthroughDataAttributeName]: option },
  ])
) as Record<
  WalkthroughStep,
  Record<typeof WalkthroughDataAttributeName, WalkthroughStep>
>;

export const ChainRestUrl: Record<CosmosChainType, string> = {
  LAVA: 'https://lava.rest.lava.build:443',
  SECRET: 'https://lcd.mainnet.secretsaturn.net',
  COSMOS: import.meta.env.VITE_REACT_APP_COSMOS_DEFAULT_REST_URL,
};

export const ChainRpcUrls: Record<
  CosmosChainType,
  { main: string; alternative: string }
> = {
  SECRET: {
    main: 'https://rpc.mainnet.secretsaturn.net',
    alternative: 'https://rpc.cosmos.directory/secretnetwork',
  },
  LAVA: {
    main: 'https://lava.tendermintrpc.lava.build:443',
    alternative: 'https://lava.tendermintrpc.lava.build:443',
  },
  COSMOS: {
    alternative: 'https://rpc.cosmos.directory/cosmoshub',
    main: import.meta.env.VITE_REACT_APP_COSMOS_DEFAULT_RPC_ENDPOINT,
  },
};
