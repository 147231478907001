import isPropValid from '@emotion/is-prop-valid';
import { ThemeProvider } from 'styled-components';
import { StyleSheetManager } from 'styled-components';
import { IStyleSheetContext } from 'styled-components/dist/models/StyleSheetManager';
import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react';

import { saveDarkTheme, getIsDarkTheme } from '../utils/storage';
import { AppTheme, DARK_THEME, LIGHT_THEME } from '../config/themes';
import { isMobile, isTablet, isDesktop, isTabletUp } from '../hooks';

interface ThemeContextProps {
  theme: AppTheme;
  isDarkTheme: boolean;
  selectTheme: (isDarkTheme?: boolean) => void;
}

interface ThemeContextProviderProps extends PropsWithChildren {
  isDarkMode?: boolean;
}

export const isThemesEnabled =
  import.meta.env?.VITE_REACT_APP_THEMES_ENABLED === 'true';

const getIsCurrentThemeDark = () =>
  getIsDarkTheme(isThemesEnabled) ??
  (window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches);

const getInitialState = (): ThemeContextProps => {
  const isDarkTheme = getIsCurrentThemeDark();
  return {
    isDarkTheme,
    selectTheme: () => {},
    theme: isDarkTheme ? DARK_THEME : LIGHT_THEME,
  };
};

const shouldForwardProp: IStyleSheetContext['shouldForwardProp'] = (
  propName,
  target
) => {
  if (typeof target === 'string') {
    return propName !== 'wrap' && isPropValid(propName);
  }

  return true;
};

export const ThemeContext = createContext<ThemeContextProps>(getInitialState());

export const ThemeContextProvider = ({
  children,
  isDarkMode,
}: ThemeContextProviderProps) => {
  const [isDarkTheme, setDarkTheme] = useState(getInitialState().isDarkTheme);
  const desktop = isDesktop();
  const mobile = isMobile();
  const tabletUp = isTabletUp();
  const tablet = isTablet();

  useEffect(() => {
    if (typeof isDarkMode === 'boolean') {
      setDarkTheme(isDarkMode);
    }
  }, [isDarkMode]);

  const selectTheme = useCallback(
    (isDark?: boolean) => {
      if (!isThemesEnabled) {
        return;
      }
      saveDarkTheme(isDark);
      setDarkTheme(getIsCurrentThemeDark());
    },
    [setDarkTheme]
  );

  const value: ThemeContextProps = useMemo(() => {
    const theme = isDarkTheme ? DARK_THEME : LIGHT_THEME;

    return {
      isDarkTheme,
      selectTheme,
      theme: {
        ...theme,
        responsive: {
          isMobile: mobile,
          isTablet: tablet,
          isDesktop: desktop,
          isTabletUp: tabletUp,
        },
      },
    };
  }, [isDarkTheme, selectTheme, desktop, tablet, tabletUp, mobile]);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    </StyleSheetManager>
  );
};

export const ThemeWrap = ({ children }: PropsWithChildren) => {
  const { theme } = useContext(ThemeContext);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
