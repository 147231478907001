import { useMemo } from 'react';

import { determineChainTypeFromAddress } from 'polli-commons-fe/utils/helpers';

import { isCosmosChainType } from 'utils/helpers';
import {
  GRANTEE_ADDRESS,
  COMPOUND_ENABLED,
  DISABLED_COMPOUND_CHAIN_TYPES,
} from 'config/constants';

import { useConnectedWallets } from '../use-connected-wallets';

export const useCompoundEnabled = (
  walletAddress: string | null | undefined
) => {
  const chainType = walletAddress
    ? determineChainTypeFromAddress(walletAddress)
    : null;
  const connectedWallets = useConnectedWallets(chainType);

  const isUserWallet = !connectedWallets.find(
    ({ wallet }) => wallet.address === walletAddress
  )?.wallet.isReadOnly;

  const currentChainTypeIsCosmosRelated =
    chainType && isCosmosChainType(chainType);
  const compoundDisabledForChainType =
    chainType && DISABLED_COMPOUND_CHAIN_TYPES.includes(chainType);
  const hasGranteeAddress = chainType && GRANTEE_ADDRESS[chainType];

  return useMemo(
    () =>
      COMPOUND_ENABLED &&
      isUserWallet &&
      currentChainTypeIsCosmosRelated &&
      !compoundDisabledForChainType &&
      hasGranteeAddress,
    [
      isUserWallet,
      hasGranteeAddress,
      compoundDisabledForChainType,
      currentChainTypeIsCosmosRelated,
    ]
  );
};
