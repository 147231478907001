import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Currency } from 'polli-commons-fe/types';

import { RootState } from 'store';

const initialCurrency: Currency = 'USD';

const currencySlice = createSlice({
  name: 'currency',
  initialState: initialCurrency as Currency,
  reducers: {
    setCurrency: (_, { payload }: PayloadAction<Currency>) => payload,
  },
});

export const {
  reducer: currencyReducer,
  actions: { setCurrency },
} = currencySlice;

export const selectCurrency = (store: RootState) => store.currency;
