import {
  Flex,
  EllipsisText,
  CosmosValidatorImage,
} from 'polli-commons-fe/components';

export const ValidatorInfo = ({
  name,
  address,
}: {
  name?: string;
  address: string;
}) => {
  return (
    <Flex gap={8} minWidth={0}>
      <CosmosValidatorImage rounded address={address} />

      <EllipsisText>{name}</EllipsisText>
    </Flex>
  );
};
