import * as Sentry from '@sentry/react';
import { Component, PropsWithChildren } from 'react';

import { Flex } from 'polli-commons-fe/components/styles';
import { Button } from 'polli-commons-fe/components/button';
import { BaseAuthPage } from 'polli-commons-fe/components/auth-page';
import { clearCacheAndReload } from 'polli-commons-fe/utils/helpers';
import { handleError, getErrorMessage } from 'polli-commons-fe/utils/error';

export class ErrorBoundary extends Component<
  PropsWithChildren,
  { error: unknown }
> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: unknown) {
    return { error: getErrorMessage(error) };
  }

  render() {
    if (this.state.error) {
      return (
        <BaseAuthPage title="Something went wrong">
          <Flex column gap={24}>
            <p>Error message: {getErrorMessage(this.state.error)}</p>
            <Button
              text="Clear cache"
              onClick={async () => {
                try {
                  this.setState({ error: null });

                  clearCacheAndReload();
                } catch (error) {
                  handleError(error);
                }
              }}
            />
          </Flex>
        </BaseAuthPage>
      );
    }

    return this.props.children;
  }

  componentDidCatch(error: unknown) {
    Sentry.captureException(error);
  }
}
