import Long from 'long';
import * as math from 'mathjs';
import * as Sentry from '@sentry/react';
import { assets } from 'chain-registry';
import { toBase64 } from '@cosmjs/encoding';
import axios, { isAxiosError } from 'axios';
import { Registry } from '@cosmjs/proto-signing';
import { Coin, AccountData } from '@keplr-wallet/types';
import { PubKey } from 'cosmos-js-telescope/cosmos/crypto/secp256k1/keys';
import { SignMode } from 'cosmos-js-telescope/cosmos/tx/signing/v1beta1/signing';
import {
  Fee,
  TxRaw,
  TxBody,
  AuthInfo,
} from 'cosmos-js-telescope/cosmos/tx/v1beta1/tx';
import {
  StdFee,
  GasPrice,
  AminoTypes,
  coin as _coin,
  SigningStargateClient,
} from '@cosmjs/stargate';
import {
  cosmosProtoRegistry,
  lavanetProtoRegistry,
  cosmosAminoConverters,
  lavanetAminoConverters,
} from 'cosmos-js-telescope';

import { getWindowProvider } from 'polli-commons-fe/utils/cosmos';
import { determineChainTypeFromAddress } from 'polli-commons-fe/utils/helpers';
import {
  ChainId,
  CosmosChainType,
  CosmosWalletProvider,
} from 'polli-commons-fe/types';

import { shiftDigits } from 'utils/math';
import { ChainRestUrl, ChainRpcUrls } from 'types/data';
import COSMOS_MESSAGE_TYPE_URL from 'config/cosmos-message-type-url';
import {
  ChainDenom,
  CosmosChainName,
  CosmosRestUrlAccount,
  ChainAverageGasPrice,
  CosmosTransactionMessage,
} from 'types';

export const coin = (amount: math.BigNumber, denom: string) =>
  _coin(math.format(math.floor(amount), { notation: 'fixed' }), denom);

export const getGrantsAmount = (chainType: CosmosChainType) =>
  coin(math.bignumber(1000000000000), ChainDenom[chainType]);

export const getChainAssets = (chainType: CosmosChainType) => {
  return (
    chainType &&
    assets.find((chain) => chain.chain_name === CosmosChainName[chainType])
  );
};

export const getCoin = (chainType: CosmosChainType) => {
  const chainAssets = getChainAssets(chainType);
  return chainAssets?.assets[0];
};

export const getExponent = (chainType: CosmosChainType) => {
  return getCoin(chainType)?.denom_units.find(
    (unit) => unit.denom === getCoin(chainType)?.display
  )?.exponent;
};

const converters = { ...cosmosAminoConverters, ...lavanetAminoConverters };

export const registry = new Registry([
  ...cosmosProtoRegistry,
  ...lavanetProtoRegistry,
]);

const aminoTypes = new AminoTypes({
  ...converters,
});

const getRpcUrlBasedOnRetriesCount = ({
  chainType,
  triesLimit,
  retriesLeft,
}: {
  triesLimit: number;
  retriesLeft: number;
  chainType: CosmosChainType;
}) => {
  const triesCount = triesLimit - retriesLeft;

  const rpcUrls = ChainRpcUrls[chainType];

  if (triesCount <= 3) {
    return rpcUrls.main;
  } else {
    return rpcUrls.alternative;
  }
};

export const getCustomStargateClient = async (
  retriesLeft: number,
  triesLimit: number,
  chainType: CosmosChainType,
  providerName: CosmosWalletProvider
): Promise<SigningStargateClient> => {
  const chainId = ChainId[chainType];
  const windowProvider = getWindowProvider(providerName);
  const offlineSigner = await windowProvider?.getOfflineSignerAuto(chainId);

  if (!offlineSigner) {
    return Promise.reject(new Error('Offline signer not available'));
  }

  const rpcEndpoint = getRpcUrlBasedOnRetriesCount({
    chainType,
    triesLimit,
    retriesLeft,
  });

  return SigningStargateClient.connectWithSigner(rpcEndpoint, offlineSigner, {
    registry,
    aminoTypes,
  });
};

const makeBodyBytes = (messages: CosmosTransactionMessage[], memo: string) => {
  const anyMsgs = messages.map((m) => registry.encodeAsAny(m));
  return TxBody.encode(
    TxBody.fromPartial({
      memo: memo,
      messages: anyMsgs,
    })
  ).finish();
};

const pubkeyTypeUrl = (pub_key: CosmosRestUrlAccount['pub_key']) => {
  if (pub_key && pub_key['@type']) return pub_key['@type'];
  return '/cosmos.crypto.secp256k1.PubKey';
};

const makeAuthInfoBytes = async (
  account: CosmosRestUrlAccount,
  fee: {
    amount: Coin[];
    gasLimit: string;
  },
  mode: SignMode,
  accountFromSigner: AccountData
) => {
  const { sequence } = account;
  return AuthInfo.encode({
    fee: Fee.fromPartial({
      amount: fee.amount,
      gasLimit: BigInt(fee.gasLimit),
    }),
    signerInfos: [
      {
        modeInfo: { single: { mode: mode } },
        sequence: Long.fromNumber(+sequence, true) as unknown as bigint,
        publicKey: {
          typeUrl: pubkeyTypeUrl(account.pub_key),
          value: PubKey.encode({
            key: accountFromSigner.pubkey,
          }).finish(),
        },
      },
    ],
  }).finish();
};

const getAccount = async (
  address: string,
  chainType: CosmosChainType
): Promise<CosmosRestUrlAccount> => {
  try {
    const res = await axios.get(
      ChainRestUrl[chainType] + '/cosmos/auth/v1beta1/accounts/' + address
    );
    let value = res.data.account;
    if (!value) {
      Sentry.captureException('Failed to fetch account, please try again', {
        extra: {
          address,
          responseData: res.data,
          restUrl: ChainRestUrl[chainType],
        },
      });

      throw new Error('Failed to fetch account, please try again');
    }

    const baseAccount =
      value.BaseAccount || value.baseAccount || value.base_account;
    if (baseAccount) {
      value = baseAccount;
    }

    const baseVestingAccount =
      value.BaseVestingAccount ||
      value.baseVestingAccount ||
      value.base_vesting_account;
    if (baseVestingAccount) {
      value = baseVestingAccount;

      const acc = value.BaseAccount || value.baseAccount || value.base_account;
      if (acc) {
        value = acc;
      }
    }

    // Handle nested account like Desmos
    const nestedAccount = value.account;
    if (nestedAccount) {
      value = nestedAccount;
    }
    return value;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      throw new Error('Account does not exist on chain');
    } else {
      throw error;
    }
  }
};

const calculateFee = (gasLimit: number, chainType: CosmosChainType): StdFee => {
  const processedGasPrice = GasPrice.fromString(
    `${ChainAverageGasPrice[chainType]}${ChainDenom[chainType]}`
  );
  const { denom, amount: gasPriceAmount } = processedGasPrice;

  const amount = math.ceil(
    math.bignumber(
      math.multiply(
        math.bignumber(gasPriceAmount.toString()).toNumber(),
        math.bignumber(gasLimit.toString()).toNumber()
      )
    )
  );
  return {
    gas: gasLimit.toString(),
    amount: [coin(amount, denom)],
  };
};

export const simulate = async ({
  msgs,
  memo,
  address,
  provider,
}: {
  memo: string;
  address: string;
  provider: CosmosWalletProvider;
  msgs: CosmosTransactionMessage[];
}): Promise<StdFee | undefined> => {
  const chainType = determineChainTypeFromAddress(address);

  const restUrl = ChainRestUrl[chainType];

  const account = await getAccount(address, chainType);
  const fee = calculateFee(100_000, chainType);
  const windowProvider = getWindowProvider(provider);
  const offlineSigner = windowProvider?.getOfflineSigner(ChainId[chainType]);
  const accountFromSigner = (await offlineSigner?.getAccounts())?.[0];

  if (accountFromSigner) {
    const txBody = {
      signatures: [new Uint8Array()],
      bodyBytes: makeBodyBytes(msgs, memo),
      authInfoBytes: await makeAuthInfoBytes(
        account,
        {
          gasLimit: fee.gas,
          amount: [...fee.amount],
        },
        SignMode.SIGN_MODE_UNSPECIFIED,
        accountFromSigner
      ),
    };

    const estimate = await axios.post(`${restUrl}/cosmos/tx/v1beta1/simulate`, {
      tx_bytes: toBase64(TxRaw.encode(txBody).finish()),
    });

    const gasUsed = parseInt(String(+estimate.data.gas_info.gas_used * 1.5));

    return calculateFee(gasUsed, chainType);
  }
};

interface DelegateValue {
  amount: string;
  delegatorAddress: string;
  validatorAddress: string;
}

export const convertAmountToCoin = (amount: string, address: string) => {
  const chain = determineChainTypeFromAddress(address);

  const chainCoin = getCoin(chain);
  const exponent = getExponent(chain);

  if (!chainCoin) {
    throw new Error('Coin not found');
  }

  if (!exponent) {
    throw new Error('Exponent not found');
  }

  return {
    denom: chainCoin.base,
    amount: shiftDigits(amount, exponent),
  };
};

const createCosmosTransactionMessage = ({
  value,
  typeUrl,
}: {
  typeUrl: string;
  value: Omit<DelegateValue, 'amount'> & { amount?: string };
}): CosmosTransactionMessage => {
  const amount = value.amount
    ? convertAmountToCoin(value.amount, value.delegatorAddress)
    : undefined;

  return {
    typeUrl,
    value: {
      ...value,
      amount,
    },
  };
};

export const buildDelegateMessage = (value: DelegateValue) =>
  createCosmosTransactionMessage({
    value,
    typeUrl: COSMOS_MESSAGE_TYPE_URL.DELEGATE,
  });

export const undelegate = (value: DelegateValue) =>
  createCosmosTransactionMessage({
    value,
    typeUrl: COSMOS_MESSAGE_TYPE_URL.UNDELEGATE,
  });

export const buildRedelegateMessage = (value: {
  amount: string;
  delegatorAddress: string;
  validatorSrcAddress: string;
  validatorDstAddress: string;
}) => {
  const amount = convertAmountToCoin(value.amount, value.delegatorAddress);

  return {
    value: {
      ...value,
      amount,
    },
    typeUrl: COSMOS_MESSAGE_TYPE_URL.BEGIN_REDELEGATE,
  };
};

export const withdrawDelegatorReward = (value: {
  delegatorAddress: string;
  validatorAddress: string;
}) =>
  createCosmosTransactionMessage({
    value,
    typeUrl: COSMOS_MESSAGE_TYPE_URL.WITHDRAW_DELEGATOR_REWARD,
  });

export const cancelUnbondingDelegation = (
  value: {
    creationHeight: number;
  } & DelegateValue
) => {
  const amount = convertAmountToCoin(value.amount, value.delegatorAddress);

  return {
    value: {
      ...value,
      amount,
    },
    typeUrl: COSMOS_MESSAGE_TYPE_URL.CANCEL_UNBONDING_DELEGATION,
  };
};
