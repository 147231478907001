import styled, { css } from 'styled-components';
import { cloneElement, SVGAttributes, isValidElement } from 'react';

import { caption, subTitle } from '../styles';
import { ellipsisText, getAccentGradientText } from '../styles/mixins';

import { IconColorParam } from './types';

export interface FlexProps {
  gap?: number;
  wrap?: boolean;
  rowGap?: number;
  column?: boolean;
  center?: boolean;
  inline?: boolean;
  minWidth?: number;
  columnGap?: number;
  flexShrink?: number;
  flex?: number | string;
  equalizeChildren?: boolean;
  alignItems?:
    | 'center'
    | 'stretch'
    | 'initial'
    | 'inherit'
    | 'flex-end'
    | 'baseline'
    | 'flex-start';
  justifyContent?:
    | 'center'
    | 'inherit'
    | 'initial'
    | 'flex-end'
    | 'flex-start'
    | 'space-around'
    | 'space-between';
}

export const Flex = styled.div<FlexProps>`
  ${({
    gap,
    flex,
    wrap,
    column,
    center,
    inline,
    rowGap,
    minWidth,
    columnGap,
    alignItems,
    flexShrink,
    justifyContent,
    equalizeChildren,
  }) => css`
    display: ${inline ? 'inline-flex' : 'flex'};

    ${equalizeChildren &&
    css`
      > * {
        flex: 1;
      }
    `}

    ${minWidth !== undefined &&
    css`
      min-width: ${minWidth}px;
    `}

    ${column &&
    css`
      flex-direction: column;
    `}

    ${gap &&
    css`
      gap: ${gap}px;
    `}

    ${rowGap &&
    css`
      row-gap: ${rowGap}px;
    `}

    ${columnGap &&
    css`
      column-gap: ${columnGap}px;
    `}

    ${center &&
    css`
      align-items: center;
      justify-content: center;
    `}

    ${alignItems &&
    css`
      align-items: ${alignItems};
    `}
    
    ${justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}
    
    ${flex &&
    css`
      flex: ${flex};
    `}
    
    ${wrap &&
    css`
      flex-wrap: wrap;
    `}

    ${flexShrink !== undefined &&
    css`
      flex-shrink: ${flexShrink};
    `};
  `}
`;

export const ThemeDependentIcon = styled(
  ({
    children,
    ...props
  }: { $iconColorParam?: IconColorParam } & SVGAttributes<SVGElement>) =>
    isValidElement(children) && cloneElement(children, props)
)`
  * {
    ${({ theme, $iconColorParam = 'fill' }) =>
      $iconColorParam === 'fill'
        ? css`
            fill: ${theme.config.text.primary.main};
          `
        : css`
            stroke: ${theme.config.text.primary.main};
          `}
  }
`;

export const Caption = styled.span`
  ${caption};
`;

export const AccentGradientIcon = styled(
  ({
    children,
    ...props
  }: { $iconColorParam?: IconColorParam } & SVGAttributes<SVGElement>) =>
    isValidElement(children) && cloneElement(children, props)
)`
  * {
    ${({ $iconColorParam = 'fill' }) =>
      $iconColorParam === 'fill'
        ? css`
            fill: url(#accent_gradient);
          `
        : css`
            stroke: url(#accent_gradient);
          `}
  }
`;

export const TranslucentCaption = styled(Caption)`
  opacity: 0.5;
`;

export const Semibold = styled.div`
  font-weight: 600;
`;

export const ErrorMessage = styled.div<{ $bold?: boolean }>`
  ${({ theme, $bold }) => css`
    color: ${theme.colors.red.primary.main};

    ${$bold &&
    css`
      font-weight: 700;
    `}
  `}
`;

export const GradientText = styled.div`
  ${getAccentGradientText()};
`;

export const LightGrayOpacity = styled.div`
  color: ${({ theme }) => theme.colors.lightGrayOpacity};
`;

export const dividerCss = css`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.config.divider};
`;

export const Divider = styled.div`
  ${dividerCss};
`;

export const Ellipsis = styled.div`
  display: flex;
  gap: 8px;

  ${ellipsisText};

  > * {
    ${ellipsisText};
  }
`;

export const GreyCaption = styled(LightGrayOpacity)`
  ${caption};
`;

export const SubTitle = styled.div`
  ${subTitle};
`;
