import { ElementType, HTMLAttributes } from 'react';

import * as Styles from './styles';

export const GradientHeader = ({
  children,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & { as?: ElementType }) => (
  <Styles.Header {...props}>
    <Styles.Text>{children}</Styles.Text>
  </Styles.Header>
);
