import { LineChart } from 'recharts';
import styled, { css } from 'styled-components';

import { caption } from 'polli-commons-fe/styles';

import { Flex } from '../styles';
import { EmptyPlaceholder as EmptyPlaceholderComponent } from '../empty-placeholder';

export const ChartContainer = styled.div`
  height: 480px;
  position: relative;
  border-radius: 36px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  padding: 24px;

  ${({ theme }) => css`
    background: ${theme.config.popup.background.main};

    ${theme.responsive.isMobile &&
    css`
      .recharts-label tspan {
        font-size: 10px;
      }
    `}
  `}

  &::after {
    top: 50%;
    content: '';
    z-index: -1;
    width: 372px;
    height: 351px;
    right: 0;
    position: absolute;
    filter: blur(92px);
    border-radius: 372px;
    transform: translateY(-50%);
    background: linear-gradient(
      214deg,
      rgba(36, 74, 221, 0.1) 8.76%,
      rgba(84, 110, 210, 0.1) 82.49%
    );
  }

  .recharts-active-dot {
    display: none;
  }

  .recharts-cartesian-axis-tick-value tspan {
    font-size: 12px;
  }
`;

export const Tooltip = styled(Flex)`
  gap: 12px;
  padding: 16px;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;

  * {
    ${caption};
  }

  ${({ theme }) => css`
    border: ${theme.config.transparentBorder};
    background: ${theme.config.tableRow.background};
    width: ${theme.responsive.isMobile ? '260px' : '268px'};
  `}
`;

export const EmptyPlaceholder = styled(EmptyPlaceholderComponent)`
  top: 43%;
  height: 85%;
  background-size: contain;
`;

export const APRChartContainer = styled.div`
  height: 30%;
  padding: 10px 0;
  border-image-slice: 1;
  position: relative;
  background: linear-gradient(
      270deg,
      rgba(251, 251, 251, 0) 0%,
      rgba(251, 251, 251, 0.06) 8.58%,
      rgba(251, 251, 251, 0.06) 79.8%,
      rgba(251, 251, 251, 0) 100%
    ),
    linear-gradient(
      270deg,
      rgba(254, 202, 68, 0) 2.69%,
      rgba(254, 202, 68, 0.1) 12.86%,
      rgba(141, 201, 42, 0.1) 33.68%,
      rgba(42, 201, 125, 0.1) 44.86%,
      rgba(9, 174, 197, 0.1) 58.57%,
      rgba(9, 120, 197, 0.1) 77.62%,
      rgba(9, 120, 197, 0) 96.93%
    );
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-image-source: linear-gradient(
    225.75deg,
    rgba(236, 205, 43, 0.4) 20.01%,
    rgba(3, 151, 80, 0.4) 54.9%,
    rgba(24, 124, 198, 0.4) 86.45%
  );

  ${({ theme }) =>
    theme.responsive.isMobile &&
    css`
      &::before {
        content: 'APR';
        position: absolute;
        transform: rotate(270deg) translateX(50%);
        font-size: 10px;
        top: 50%;
        z-index: 1;
      }
    `}
`;

export const ChartInner = styled(Flex)`
  gap: 24px;
  height: 75%;
  flex-direction: column;
`;

export const DateLineChart = styled(LineChart)`
  z-index: -1;
`;

export const TooltipRowDivider = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 32px;
  background: ${({ theme }) => theme.colors.accent.primary.main};
`;

export const WithdrawalLegendCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;

  ${({ theme }) => css`
    border: ${theme.config.transparentBorder};
    background: ${theme.colors.yellowGradient};
  `}
`;

export const RewardsChartContainer = styled.div`
  height: 100%;
`;
