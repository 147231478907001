import { Formik, FormikValues } from 'formik';

import { Flex } from '../styles';
import { Button } from '../button';
import { handleError } from '../../utils/error';

import * as Styles from './styles';
import { DialogProps } from './types';

export const Dialog = <Values extends FormikValues = FormikValues>({
  open,
  title,
  onOpen,
  height,
  trigger,
  onClose,
  children,
  onSubmit,
  onCancel,
  className,
  titleStyle,
  initialValues,
  width = '500px',
  showCancel = true,
  closeOnSubmit = true,
  closeOnDocumentClick,
  confirmText = 'Confirm',
  showBlurBackground = true,
  ...formikProps
}: DialogProps<Values>) => {
  return (
    <Styles.Popup
      modal
      open={open}
      width={width}
      onOpen={onOpen}
      height={height}
      trigger={trigger}
      onClose={onClose}
      className={className}
      closeOnEscape={showCancel}
      closeOnDocumentClick={
        closeOnDocumentClick === undefined ? showCancel : closeOnDocumentClick
      }
    >
      {(close) => {
        return (
          <Styles.Container>
            {showBlurBackground && <Styles.BlurBackground />}

            {typeof title === 'string' ? (
              <Styles.Title>{title}</Styles.Title>
            ) : (
              title?.(close)
            )}

            {onSubmit && initialValues ? (
              <Formik<Values>
                validateOnBlur={false}
                initialValues={initialValues}
                onSubmit={async (...onSubmitProps) => {
                  try {
                    await onSubmit(...onSubmitProps);
                    if (closeOnSubmit) {
                      close();
                    }
                  } catch (error) {
                    handleError(error);
                  }
                }}
                {...formikProps}
              >
                {(formik) => (
                  <Styles.Form>
                    <Flex column gap={12}>
                      {typeof children === 'function'
                        ? children({ close, ...formik })
                        : children}
                    </Flex>

                    <Styles.PopupFooter>
                      {(showCancel || onCancel) && (
                        <Button
                          text="Cancel"
                          styleType="secondary"
                          onClick={onCancel || close}
                        />
                      )}

                      <Button
                        type="submit"
                        text={confirmText}
                        isLoading={formik.isSubmitting}
                      />
                    </Styles.PopupFooter>
                  </Styles.Form>
                )}
              </Formik>
            ) : typeof children === 'function' ? (
              children({ close })
            ) : (
              children
            )}
          </Styles.Container>
        );
      }}
    </Styles.Popup>
  );
};

export type { DialogProps };
