import * as Sentry from '@sentry/react';
import storage from 'redux-persist/lib/storage';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  createMigrate,
  persistReducer,
} from 'redux-persist';

import { ChainId } from 'polli-commons-fe/types';
import { rtkQueryErrorHandler } from 'polli-commons-fe/store/store';
import { chainsDataApi } from 'polli-commons-fe/store/api/chains-data';
import { currencyReducer } from 'polli-commons-fe/store/slices/currency';
import { exchangeRatesApi } from 'polli-commons-fe/store/api/exchange-rates';
import { cosmosValidatorsHistoryApi } from 'polli-commons-fe/store/api/cosmos-validators-history';

import { reportsApi } from './api/reports';
import { walletsApi } from './api/wallets';
import { feedbackApi } from './api/feedback';
import { userGrantsApi } from './api/user-grants';
import { compoundingApi } from './api/compounding';
import { lavaRestakeApi } from './api/lava-restake';
import { notificationsApi } from './api/notifications';
import { cosmosWalletsApi } from './api/cosmos-wallets';
import { authSlice, authInitialState } from './slices/auth';
import { trezorSlice, trezorReducer } from './slices/trezor';
import { walletsSummariseApi } from './api/wallets-summarise';
import { cosmosValidatorsApi } from './api/cosmos-validators';
import { lavaRestakeSpecsApi } from './api/lava-restake-specs';
import { cosmosDelegationsApi } from './api/cosmos-delegations';
import { ethereumValidatorsApi } from './api/ethereum-validators';
import { metamaskSlice, metamaskReducer } from './slices/metamask';
import { cosmosMultiValidators } from './api/cosmos-multi-validators';
import { cosmosMultiDelegationsApi } from './api/cosmos-multi-delegations';
import { walkthroughSlice, walkthroughReducer } from './slices/walkthrough';
import { ethereumMultiValidatorsApi } from './api/ethereum-multi-validators';
import { connectedCosmosChainsSlice } from './slices/connected-cosmos-chains';
import { maxValidatorsExceededSlice } from './slices/max-validators-exceeded';
import { delegationProcessStateSlice } from './slices/delegation-process-state';
import {
  rewardsDrawerSlice,
  rewardsDrawerReducer,
} from './slices/rewards-drawer';
import { mobileDeviceSelectedWalletsSlice } from './slices/mobile-device-selected-wallets';
import { alreadyLinkedWalletAddressesSlice } from './slices/already-linked-wallets-addresses';
import {
  transactionLoaderSlice,
  transactionLoaderReducer,
} from './slices/transaction-loader';

const migrations = {
  1: (state: any) => {
    return {
      ...state,
      auth: authInitialState,
    };
  },
  0: (state: any) => {
    return {
      ...state,
      connectedCosmosChains: {
        providerName: null,
        chains: {
          [ChainId.COSMOS]: {
            address: null,
          },
          [ChainId.SECRET]: {
            address: null,
          },
        },
      },
    };
  },
};

const persistConfig = {
  storage,
  version: 1,
  key: 'root',
  migrate: createMigrate(migrations),
  whitelist: [
    'auth',
    'currency',
    trezorSlice.name,
    metamaskSlice.name,
    connectedCosmosChainsSlice.name,
    mobileDeviceSelectedWalletsSlice.name,
  ],
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const appReducer = combineReducers({
  currency: currencyReducer,
  [trezorSlice.name]: trezorReducer,
  [authSlice.name]: authSlice.reducer,
  [metamaskSlice.name]: metamaskReducer,
  [walkthroughSlice.name]: walkthroughReducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [walletsApi.reducerPath]: walletsApi.reducer,
  [feedbackApi.reducerPath]: feedbackApi.reducer,
  [rewardsDrawerSlice.name]: rewardsDrawerReducer,
  [chainsDataApi.reducerPath]: chainsDataApi.reducer,
  [userGrantsApi.reducerPath]: userGrantsApi.reducer,
  [compoundingApi.reducerPath]: compoundingApi.reducer,
  [lavaRestakeApi.reducerPath]: lavaRestakeApi.reducer,
  [transactionLoaderSlice.name]: transactionLoaderReducer,
  [exchangeRatesApi.reducerPath]: exchangeRatesApi.reducer,
  [cosmosWalletsApi.reducerPath]: cosmosWalletsApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [lavaRestakeSpecsApi.reducerPath]: lavaRestakeSpecsApi.reducer,
  [walletsSummariseApi.reducerPath]: walletsSummariseApi.reducer,
  [cosmosValidatorsApi.reducerPath]: cosmosValidatorsApi.reducer,
  [cosmosDelegationsApi.reducerPath]: cosmosDelegationsApi.reducer,
  [cosmosMultiValidators.reducerPath]: cosmosMultiValidators.reducer,
  [ethereumValidatorsApi.reducerPath]: ethereumValidatorsApi.reducer,
  [maxValidatorsExceededSlice.name]: maxValidatorsExceededSlice.reducer,
  [connectedCosmosChainsSlice.name]: connectedCosmosChainsSlice.reducer,
  [delegationProcessStateSlice.name]: delegationProcessStateSlice.reducer,
  [cosmosMultiDelegationsApi.reducerPath]: cosmosMultiDelegationsApi.reducer,
  [cosmosValidatorsHistoryApi.reducerPath]: cosmosValidatorsHistoryApi.reducer,
  [ethereumMultiValidatorsApi.reducerPath]: ethereumMultiValidatorsApi.reducer,
  [mobileDeviceSelectedWalletsSlice.name]:
    mobileDeviceSelectedWalletsSlice.reducer,
  [alreadyLinkedWalletAddressesSlice.name]:
    alreadyLinkedWalletAddressesSlice.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/clearAuth') return appReducer(undefined, action);
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
  /*TODO*/
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      rtkQueryErrorHandler({
        // Ignore chainsDataApi error because some currencies are not supported and don't need to show error
        ignore: [chainsDataApi.reducerPath],
      }),
      reportsApi.middleware,
      walletsApi.middleware,
      feedbackApi.middleware,
      userGrantsApi.middleware,
      chainsDataApi.middleware,
      compoundingApi.middleware,
      lavaRestakeApi.middleware,
      cosmosWalletsApi.middleware,
      exchangeRatesApi.middleware,
      notificationsApi.middleware,
      walletsSummariseApi.middleware,
      lavaRestakeSpecsApi.middleware,
      cosmosValidatorsApi.middleware,
      cosmosDelegationsApi.middleware,
      cosmosMultiValidators.middleware,
      ethereumValidatorsApi.middleware,
      cosmosMultiDelegationsApi.middleware,
      ethereumMultiValidatorsApi.middleware,
      cosmosValidatorsHistoryApi.middleware
    ),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
