import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Flex } from 'polli-commons-fe/components';
import { CosmosChainType } from 'polli-commons-fe/types';

import bgStars from 'assets/deposit-confirmation/success-stars.svg';

import { iconBgImage } from './config';

export const Container = styled(motion.div)`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: url(${bgStars}) no-repeat center / contain;
  flex-direction: column;
  gap: 40px;
  display: flex;
`;

export const InfoContainer = styled.div`
  width: 488px;
  position: relative;
  padding: 24px;
`;

export const InfoBackground = styled.div`
  width: 100%;
  height: 100%;
  inset: 0;
  position: absolute;
  border-radius: 54px;
  background: rgba(4, 19, 1, 0.01);
  filter: blur(10.5px);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  z-index: -1;
`;

export const TitleBottomSlotContainer = styled(Flex)<{
  chainType: CosmosChainType;
}>`
  width: 488px;
  height: 128px;
  align-items: center;
  justify-content: center;
  background: ${({ chainType }) =>
    `url(${iconBgImage[chainType]}) center / cover`};
`;
