import styled from 'styled-components';

import { gradientBorder } from 'polli-commons-fe/styles/mixins';

export const AllRewardsContainer = styled.div`
  ${gradientBorder};
  border-radius: 40px;

  &::before {
    z-index: 1;
    padding: 2px;
  }
`;

export const AllRewards = styled.div`
  display: flex;
  min-width: 145px;
  position: relative;
  justify-content: center;
  background: ${({ theme }) => theme.config.tableRow.background};
  padding: 12px 32px;
  border-radius: inherit;
  box-shadow:
    0 0 24px 0 rgba(226, 203, 46, 0.18),
    0 2px 4px 0 rgba(4, 19, 1, 0.24);
`;

export const Canvas = styled.canvas`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: inherit;
`;
