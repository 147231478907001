import styled from 'styled-components';
import { List } from 'react-virtualized';

import { HeadersRow as HeadersRowComponent } from 'polli-commons-fe/components/table/headers-row';

import { rowsGap } from './config';

export const VirtualizedList = styled(List)`
  padding: 0 16px;
`;

export const TableRowWrapper = styled.div`
  padding-bottom: ${rowsGap}px;
`;

export const HeadersRow = styled(HeadersRowComponent)`
  margin-bottom: 20px;
`;
