import styled from 'styled-components';
import { motion } from 'framer-motion';

export const TabsList = styled.ul`
  gap: 24px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.config.table.tabs.border};
  overflow: auto;
`;

export const Tab = styled.li`
  cursor: pointer;
  padding: 12px 0 16px 0;
  position: relative;
`;

export const Underline = styled(motion.div)`
  left: 0;
  right: 0;
  height: 4px;
  bottom: 0;
  position: absolute;
  border-radius: 24px;
  background: linear-gradient(
    238deg,
    #feca44 -14.75%,
    #8dc92a 11.26%,
    #2ac97d 38.45%,
    #09aec5 67.98%,
    #0978c5 95.59%
  );
`;
