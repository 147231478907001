import dayjs from 'dayjs';
import * as math from 'mathjs';
import { Coin } from '@keplr-wallet/types';
import { cosmos } from 'cosmos-js-telescope';

import { CosmosChainType } from 'polli-commons-fe/types';
import { determineChainTypeFromAddress } from 'polli-commons-fe/utils/helpers';

import { ChainDenom } from 'types';
import { GRANTEE_ADDRESS } from 'config/constants';
import { coin, getGrantsAmount } from 'utils/cosmos';
import COSMOS_MESSAGE_TYPE_URL from 'config/cosmos-message-type-url';

const { Grant, MsgGrant, MsgRevoke } = cosmos.authz.v1beta1;

const { AuthorizationType, StakeAuthorization, StakeAuthorization_Validators } =
  cosmos.staking.v1beta1;

const { SendAuthorization } = cosmos.bank.v1beta1;

const { BasicAllowance, MsgGrantAllowance, MsgRevokeAllowance } =
  cosmos.feegrant.v1beta1;

const getExpirationDate = () => {
  const now = dayjs();

  const yearsToAdd = 3;
  const daysToAdd = 1;

  const futureDate = now.add(yearsToAdd, 'year').add(daysToAdd, 'day');
  return futureDate.toDate();
};

export const calculateCompoundGrantLimitForStaking = (
  delegationAmountInUatoms: number
) => {
  // Annual Percentage Rate (APR) as a decimal
  const apr = 0.25;

  // Number of compounding periods per year (daily compounding)
  const compoundPeriodsPerYear = 365;

  // Total time period for compounding in years
  const timePeriodInYears = 3;

  // Additional percentage added for safety (e.g., 0.1 for 10%)
  const safetyMargin = 0.1;

  // Convert delegation amount from uatoms to atoms
  // 1 ATOM = 1e6 uatoms
  const delegationAmountInAtoms = delegationAmountInUatoms / 1e6;

  // Calculate the daily interest rate
  // (1 + APR/n)
  const dailyRate = 1 + apr / compoundPeriodsPerYear;

  // Calculate the total number of compounding periods
  // (n * t)
  const exponent = compoundPeriodsPerYear * timePeriodInYears;

  // Calculate the compound interest factor
  // ((1 + APR/n)^(nt) - 1)
  const compoundInterest = Math.pow(dailyRate, exponent) - 1;

  // Calculate the amount after applying compound interest
  // delegationAmountInAtoms * compoundInterest
  const amountAfterInterest = delegationAmountInAtoms * compoundInterest;

  // Apply the safety margin
  // amountAfterInterest * (1 + Safety Margin)
  const grantLimit = amountAfterInterest * (1 + safetyMargin);

  return grantLimit * 1e6;
};

const calculateTransferGrantLimit = (delegationAmountInUatoms: number) =>
  delegationAmountInUatoms * 0.02;

const formatCompoundSpendLimit = (amount: number, chainType: CosmosChainType) =>
  coin(math.bignumber(Math.ceil(amount)), ChainDenom[chainType]);

export const buildGrantMsgForStaking = (
  granterAddress: string,
  validatorAddresses: string[],
  delegationAmountInUatoms: number
) => {
  const expiredDate = getExpirationDate();
  const chainType = determineChainTypeFromAddress(granterAddress);
  const granteeAddress = GRANTEE_ADDRESS[chainType];

  const maxTokens = formatCompoundSpendLimit(
    calculateCompoundGrantLimitForStaking(delegationAmountInUatoms),
    chainType
  );

  return {
    typeUrl: COSMOS_MESSAGE_TYPE_URL.GRANT,
    value: MsgGrant.fromPartial({
      granter: granterAddress,
      grantee: granteeAddress,
      grant: Grant.fromPartial({
        expiration: expiredDate,
        authorization: {
          typeUrl: COSMOS_MESSAGE_TYPE_URL.STAKE_AUTHORIZATION,
          value: StakeAuthorization.encode(
            StakeAuthorization.fromPartial({
              maxTokens,
              authorizationType: AuthorizationType.AUTHORIZATION_TYPE_DELEGATE,
              allowList: StakeAuthorization_Validators.fromPartial({
                address: validatorAddresses,
              }),
            })
          ).finish(),
        },
      }),
    }),
  };
};

export const buildGrantMsgForRedelegate = (
  granterAddress: string,
  allValidatorsAddresses: string[]
) => {
  const expiration = getExpirationDate();
  const chainType = determineChainTypeFromAddress(granterAddress);
  const granteeAddress = GRANTEE_ADDRESS[chainType]!;

  return {
    typeUrl: COSMOS_MESSAGE_TYPE_URL.GRANT,
    value: MsgGrant.fromPartial({
      granter: granterAddress,
      grantee: granteeAddress,
      grant: Grant.fromPartial({
        expiration,
        authorization: {
          typeUrl: COSMOS_MESSAGE_TYPE_URL.STAKE_AUTHORIZATION,
          value: StakeAuthorization.encode(
            StakeAuthorization.fromPartial({
              maxTokens: getGrantsAmount(chainType),
              authorizationType:
                AuthorizationType.AUTHORIZATION_TYPE_REDELEGATE,
              allowList: StakeAuthorization_Validators.fromPartial({
                address: allValidatorsAddresses,
              }),
            })
          ).finish(),
        },
      }),
    }),
  };
};

export const buildGrantMsgForTransfers = (
  granterAddress: string,
  delegationAmount: number
) => {
  const expiredDate = getExpirationDate();

  const chainType = determineChainTypeFromAddress(granterAddress);
  const granteeAddress = GRANTEE_ADDRESS[chainType]!;

  const spendLimit = formatCompoundSpendLimit(
    calculateTransferGrantLimit(delegationAmount),
    chainType
  );

  const authorizationValue: {
    spendLimit: Coin[];
    allowList?: string[];
  } = {
    spendLimit: [spendLimit],
  };

  return {
    typeUrl: COSMOS_MESSAGE_TYPE_URL.GRANT,
    value: MsgGrant.fromPartial({
      granter: granterAddress,
      grantee: granteeAddress,
      grant: Grant.fromPartial({
        expiration: expiredDate,
        authorization: {
          typeUrl: COSMOS_MESSAGE_TYPE_URL.SEND_AUTHORIZATION,
          value: SendAuthorization.encode(
            SendAuthorization.fromPartial(authorizationValue)
          ).finish(),
        },
      }),
    }),
  };
};

export const buildGrantMsgForFee = (granterAddress: string) => {
  const expiration = getExpirationDate();
  const chainType = determineChainTypeFromAddress(granterAddress);
  const granteeAddress = GRANTEE_ADDRESS[chainType]!;

  return {
    typeUrl: COSMOS_MESSAGE_TYPE_URL.GRANT_ALLOWANCE,
    value: MsgGrantAllowance.fromPartial({
      granter: granterAddress,
      grantee: granteeAddress,
      allowance: {
        typeUrl: COSMOS_MESSAGE_TYPE_URL.BASIC_ALLOWANCE,
        value: BasicAllowance.encode(
          BasicAllowance.fromPartial({
            expiration,
            spendLimit: [getGrantsAmount(chainType)],
          })
        ).finish(),
      },
    }),
  };
};

export const buildRevokeMessage = ({
  type,
  granterAddress,
}: {
  type: string;
  granterAddress: string;
}) => {
  const chainType = determineChainTypeFromAddress(granterAddress);
  const granteeAddress = GRANTEE_ADDRESS[chainType]!;

  return {
    typeUrl: COSMOS_MESSAGE_TYPE_URL.REVOKE,
    value: MsgRevoke.fromPartial({
      msgTypeUrl: type,
      granter: granterAddress,
      grantee: granteeAddress,
    }),
  };
};

export const buildRevokeStakingMsg = ({
  granterAddress,
}: {
  granterAddress: string;
}) =>
  buildRevokeMessage({
    granterAddress,
    type: COSMOS_MESSAGE_TYPE_URL.DELEGATE,
  });

export const buildRevokeTransferMsg = ({
  granterAddress,
}: {
  granterAddress: string;
}) =>
  buildRevokeMessage({
    granterAddress,
    type: COSMOS_MESSAGE_TYPE_URL.SEND,
  });

export const buildRevokeFeesMsg = ({
  granterAddress,
}: {
  granterAddress: string;
}) => {
  const chainType = determineChainTypeFromAddress(granterAddress);
  const granteeAddress = GRANTEE_ADDRESS[chainType]!;

  return {
    typeUrl: COSMOS_MESSAGE_TYPE_URL.REVOKE_ALLOWANCE,
    value: MsgRevokeAllowance.fromPartial({
      granter: granterAddress,
      grantee: granteeAddress,
    }),
  };
};
