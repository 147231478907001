import styled from 'styled-components';

import { getAccentGradientText } from '../../styles/mixins';

export const Header = styled.h3``;

export const Text = styled.span`
  ${getAccentGradientText()};
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-transform: uppercase;
`;
