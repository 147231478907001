import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getCosmosWalletAddress } from 'polli-commons-fe/utils/cosmos';
import { CosmosChainType, CosmosWalletProvider } from 'polli-commons-fe/types';

import { saveAuthTypeToLocalStorage } from 'store/slices/auth/helpers';
import { connectCosmosChains } from 'store/slices/connected-cosmos-chains';
import {
  clearWalletSignInTokens,
  generateAndSaveWalletSignInTokens,
} from 'utils/wallet-sign-in';

import { AuthUser, AuthType, AuthEmail } from './types';

export interface Auth {
  authUser: AuthUser | null;
}

export const authInitialState: Auth = {
  authUser: null,
};

export const walletSignIn = createAsyncThunk(
  'auth/walletSignIn',
  async (
    {
      chainType,
      providerName,
    }: { chainType: CosmosChainType; providerName: CosmosWalletProvider },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const walletAddress = await getCosmosWalletAddress({
        chainType,
        providerName,
      });

      if (walletAddress) {
        const authUserSub = await generateAndSaveWalletSignInTokens({
          chainType,
          providerName,
          walletAddress,
        });

        dispatch(connectCosmosChains('keplr'));

        return authUserSub;
      }
    } catch (e) {
      if ((e as any).status === 404) {
        return rejectWithValue(
          "We couldn't fetch your wallet details because you haven't made any transactions yet. To proceed, please ensure your account has sufficient tokens"
        );
      }

      return rejectWithValue(e);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  extraReducers: (builder) => {
    builder.addCase(walletSignIn.fulfilled, (state, { payload }) => {
      state.authUser = {
        sub: payload,
      };
      saveAuthTypeToLocalStorage('wallet');
    });
  },
  reducers: {
    clearAuth: () => {
      clearWalletSignInTokens();
      return authInitialState;
    },
    setAuthUser: (state, { payload }) => {
      state.authUser = {
        ...state.authUser,
        ...payload,
      };
    },
  },
});

export const {
  actions: { clearAuth, setAuthUser },
} = authSlice;

export * from './selectors';

export type { AuthUser, AuthType, AuthEmail };
