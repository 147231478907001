const URL = {
  WALLETS: 'wallets',
  REPORTS: 'reports',
  FEEDBACKS: 'feedbacks',
  REPORTS_CSV: 'reports/csv',
  COSMOS_VALIDATORS: 'validators',
  ETHEREUM_WALLETS: 'ethereum-wallets',
  READONLY_WALLETS: 'read-only-wallets',
  WALLETS_SUMMARISE: 'wallets-summarise',
  USER_GRANTS: '/users/{userUUID}/grants',
  NOTIFICATIONS: 'notifications/users/{userUUID}',
  READONLY_WALLET: 'read-only-wallets/{walletId}',
  COSMOS_DELEGATIONS: 'delegations/{walletAddress}',
  LAVA_RESTAKE_PROVIDERS: '/lava/restake/providers',
  COSMOS_MULTI_DELEGATIONS: 'multi-delegations/{walletAddress}',
  ETHEREUM_VALIDATORS_ASSET: 'ethereum-validators/{validatorId}/asset',
  ETHEREUM_MULTI_VALIDATORS_ASSETS: 'ethereum-multi-validators/assets',
  ETHEREUM_VALIDATORS_INCOME: 'ethereum-validators/{validatorId}/income',
  ETHEREUM_MULTI_VALIDATORS_INCOMES: 'ethereum-multi-validators/incomes',
  LAVA_RESTAKE_DELEGATIONS: '/lava/restake/delegations/{delegatorAddress}',
  ETHEREUM_VALIDATORS_DEPOSIT: 'ethereum-validators/{validatorId}/deposit',
  ETHEREUM_MULTI_VALIDATORS_SUMMARISE: 'ethereum-multi-validators/summarise',
  ETHEREUM_VALIDATORS_INCOME_HISTORY:
    'ethereum-validators/{validatorId}/income-history',
  ETHEREUM_VALIDATORS_BALANCE_HISTORY:
    'ethereum-validators/{validatorId}/balance-history',
  ETHEREUM_MULTI_VALIDATORS_INCOME_HISTORY_LAST:
    'ethereum-multi-validators/income-history/last',
  COSMOS_MULTI_VALIDATORS_BALANCE_HISTORY:
    'multi-validators/balance-history/wallets/{walletAddress}',
  COSMOS_VALIDATORS_BALANCE_HISTORY:
    'validators/{validatorAddress}/balance-history/wallets/{walletAddress}',
  ETHEREUM_MULTI_VALIDATORS_BALANCE_HISTORY_AGGREGATION:
    'ethereum-multi-validators/balance-history/aggregation',
  COMPOUNDING_AVAILABLE_COMPOUNDS_BY_DELEGATOR:
    'compounding/grantees/{granteeWalletAddress}/available-delegators/{delegatorWalletAddress}',
};

export { URL };
