const URL = {
  EXCHANGE_RATES: 'exchange-rates',
  CHAIN_CURRENCY_DATA: 'chains-data/currencies/{currency}',
  REFRESH_TOKEN: 'https://walletsignin.dev.polli.co/api/auth/refresh',
  VERIFY_CHALLENGE:
    'https://walletsignin.dev.polli.co/api/auth/challenges/verify',
  COSMOS_VALIDATORS_INCOME_HISTORY:
    'multi-validators/income-history/wallets/{walletAddress}',
  GENERATE_SIGNATURE_MESSAGE:
    'https://walletsignin.dev.polli.co/api/auth/challenges/generate',
  COSMOS_VALIDATOR_ICON:
    'https://raw.githubusercontent.com/cosmostation/chainlist/main/chain/{chainType}/moniker/{validatorAddress}.png',
};

export { URL };
