import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Button } from '../../button';
import { EllipsisText } from '../../ellipsis-text';

export const BalanceDetailsLabel = styled(EllipsisText)`
  max-width: 200px;
`;

export const IconContainer = styled.div`
  display: flex;

  svg,
  img {
    width: 12px;
    height: 12px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ItemsContainer = styled(motion.div)`
  overflow: hidden;
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

export const ArrowButton = styled(Button)`
  pointer-events: auto;
`;
