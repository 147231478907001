import { CosmosChainType } from 'polli-commons-fe/types';

export const COMPOUND_ENABLED: boolean = import.meta.env
  .VITE_REACT_APP_COMPOUNDING_ENABLED
  ? JSON.parse(import.meta.env.VITE_REACT_APP_COMPOUNDING_ENABLED)
  : false;

export const GRANTEE_ADDRESS: Record<CosmosChainType, string | undefined> = {
  LAVA: import.meta.env.VITE_REACT_APP_COMPOUNDING_LAVA_SERVICE_WALLET,
  COSMOS: import.meta.env.VITE_REACT_APP_COMPOUNDING_COSMOS_SERVICE_WALLET,
  SECRET: import.meta.env.VITE_REACT_APP_COMPOUNDING_SECRET_SERVICE_WALLET,
};

const SECRET_NETWORK_ENABLED: boolean = import.meta.env
  .VITE_REACT_APP_SECRET_NETWORK_ENABLED
  ? JSON.parse(import.meta.env.VITE_REACT_APP_SECRET_NETWORK_ENABLED)
  : false;

const LAVA_NETWORK_ENABLED: boolean = import.meta.env
  .VITE_REACT_APP_LAVA_NETWORK_ENABLED
  ? JSON.parse(import.meta.env.VITE_REACT_APP_LAVA_NETWORK_ENABLED)
  : false;

export const ENABLED_COSMOS_NETWORK_CHAIN_TYPES = [
  'COSMOS',
  SECRET_NETWORK_ENABLED ? 'SECRET' : undefined,
  LAVA_NETWORK_ENABLED ? 'LAVA' : undefined,
].filter(Boolean) as CosmosChainType[];

export const DISABLED_COMPOUND_CHAIN_TYPES: CosmosChainType[] = ['LAVA'];
