import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const CosmosChainTypeOptions = ['SECRET', 'COSMOS', 'LAVA'] as const;
export type CosmosChainType = (typeof CosmosChainTypeOptions)[number];
export const ChainTypeOptions = [
  'ETHEREUM',
  ...CosmosChainTypeOptions,
] as const;
export type ChainType = (typeof ChainTypeOptions)[number];

export type SortTableOrder = 'asc' | 'desc';

export interface SortRequest {
  sort: Record<string, SortTableOrder>;
}

export interface BaseQueryProps {
  isError?: boolean;
  refetch?: () => void;
  isFetching?: boolean;
}

export interface PaginationRequest {
  size?: number;
  page: string | number;
}

export const CosmosChainTypeCurrencyOptions = [
  'uscrt',
  'uatom',
  'ulava',
] as const;
export type CosmosChainTypeCurrency =
  (typeof CosmosChainTypeCurrencyOptions)[number];

export interface AmountResponse {
  value: string;
  currency: 'wei' | 'gwei' | CosmosChainTypeCurrency;
}

export interface PaginationResponse {
  pagination: {
    totalCount: number;
  };
}

export interface CanvasBgDotParameters {
  x: number;
  y: number;
  size: number;
}

export interface Entity {
  id: number;
}

export type CurrencyLabel = 'ETH' | 'ATOM' | 'SCRT' | 'LAVA';

type NotificationType = 'INFO' | 'ACTION_REQUIRED';

type NotificationSeverity = 'LOW' | 'HIGH' | 'MEDIUM';

export interface Notification extends Entity {
  title: string;
  message: string;
  userUUID: string;
  email: string | null;
  type: NotificationType;
  severity: NotificationSeverity;
  noticedTimestampInMilli: number;
}

export const NotificationSeverityLabel: Record<NotificationSeverity, string> = {
  LOW: 'Low',
  HIGH: 'High',
  MEDIUM: 'Medium',
};

export interface NotificationsListResponse extends PaginationResponse {
  data: Notification[];
}

export interface QueryReturnProps<T = unknown>
  extends Required<BaseQueryProps> {
  data?: T;
  error?: Error | SerializedError | FetchBaseQueryError;
}

export const BalanceRangeOptions = [
  'LAST_THIRTY_DAYS',
  'LAST_THREE_MONTH',
  'LAST_YEAR',
] as const;
export type BalanceRange = (typeof BalanceRangeOptions)[number];

export type Currency = 'ETH' | 'BTC' | 'USD';

export interface BalanceHistoryCompound {
  validatorAddress: string;
  compoundAmount: AmountResponse;
}

export type ValidatorStatus =
  | 'EXITED'
  | 'PENDING'
  | 'SLASHED'
  | 'DEPOSITED'
  | 'ACTIVE_ONLINE'
  | 'ACTIVE_OFFLINE'
  | 'EXITING_ONLINE'
  | 'EXITING_OFFLINE'
  | 'SLASHING_ONLINE'
  | 'SLASHING_OFFLINE'
  | 'DEPOSITED_INVALID';

export type IncomeHistoryStatus = 'SUCCESS' | 'PENALIZED';

export const CosmosWalletProviderOptions = [
  'leap',
  'keplr',
  'cosmostation',
] as const;
export type CosmosWalletProvider = (typeof CosmosWalletProviderOptions)[number];

export enum ChainId {
  COSMOS = 'cosmoshub-4',
  SECRET = 'secret-4',
  LAVA = 'lava-mainnet-1',
}
