import { createApi } from '@reduxjs/toolkit/query/react';

import { SortRequest } from 'polli-commons-fe/types';
import { cosmosApiQuery } from 'polli-commons-fe/store/query';
import { getSortParams, addParamsToUrl } from 'polli-commons-fe/utils/query';

import { URL } from 'api/constants';
import { LavaRestakeProviderData } from 'store/api/lava-restake/types';

export const lavaRestakeSpecsApi = createApi({
  baseQuery: cosmosApiQuery,
  reducerPath: 'lavaRestakeSpecsApi',
  endpoints: (build) => ({
    getLavaRestakeProviders: build.query<
      LavaRestakeProviderData[],
      void | Partial<SortRequest> | undefined
    >({
      query: (params) => ({
        method: 'get',
        url: addParamsToUrl(
          URL.LAVA_RESTAKE_PROVIDERS,
          getSortParams(params?.sort)
        ),
      }),
    }),
  }),
});

export const { useGetLavaRestakeProvidersQuery } = lavaRestakeSpecsApi;
