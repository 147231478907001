import * as math from 'mathjs';
import { toast, Bounce, ToastContent } from 'react-toastify';

import { URL } from 'polli-commons-fe/api/constants';
import { CosmosChainType } from 'polli-commons-fe/types';
import { replaceAllWithMap } from 'polli-commons-fe/utils/string';

export const showError = <T extends ToastContent = ToastContent>(
  ...params: Parameters<typeof toast.error<T>>
) => toast.error<T>(...params);

export const showSuccess = <T extends ToastContent = ToastContent>(
  ...params: Parameters<typeof toast.success<T>>
) => toast.success<T>(...params);

export const showMessage = (message: string) =>
  toast(message, {
    autoClose: 600,
    draggable: false,
    transition: Bounce,
    closeButton: false,
    hideProgressBar: true,
    position: 'bottom-center',
  });

export const formatNumber = (num: number | string, fractionDigits = 5) => {
  const parsedNumber = Number(num);

  if (Number.isInteger(parsedNumber)) {
    return parsedNumber;
  }

  return parseFloat(parsedNumber.toFixed(fractionDigits));
};

export const formatSmallNumber = (
  num: number | string,
  additionalDecimalPlaces = 0
) => {
  const bigNum = math.bignumber(num);
  const stringNum = num.toString();

  if (bigNum.lessThan(1) && bigNum.greaterThan(0)) {
    if (stringNum.includes('e-') && bigNum.e) {
      return bigNum.toFixed(Math.abs(bigNum.e) + additionalDecimalPlaces);
    }

    const [whole, fractionDigits] = stringNum.split('.');

    if (fractionDigits) {
      if (fractionDigits.includes('e-') && bigNum.e) {
        return bigNum.toFixed(Math.abs(bigNum.e) + additionalDecimalPlaces);
      }

      const sliced = fractionDigits.slice(0, 5);
      const onlyZeros = /^0+$/.test(sliced);

      if (onlyZeros) {
        const regex = /^0*[^0]/;
        const match = fractionDigits.match(regex);

        const firstNonZeroPart = match?.[0];

        if (firstNonZeroPart) {
          const result = `${whole}.${firstNonZeroPart}`;

          if (additionalDecimalPlaces) {
            return `${result}${fractionDigits.slice(
              firstNonZeroPart.length,
              firstNonZeroPart.length + additionalDecimalPlaces
            )}`;
          }

          return result;
        }
      }
    }
  }

  return String(formatNumber(num));
};

export const addAlphaToColor = (
  color: `${'rgb' | 'rgba'}${string}`,
  alpha: number
) => {
  if (color.startsWith('rgba')) {
    return color.replace(
      /rgba\((\d+\s*,\s*\d+\s*,\s*\d+)\s*,\s*[\d.]+\)/,
      `rgba($1, ${alpha})`
    );
  } else if (color.startsWith('rgb')) {
    return color.replace(
      /rgb\((\d+\s*,\s*\d+\s*,\s*\d+)\)/,
      `rgba($1, ${alpha})`
    );
  } else {
    return color;
  }
};

export const isFilterApplied = (value: unknown): boolean => {
  if (value instanceof Set) {
    return value.size > 0;
  } else if (Array.isArray(value) || typeof value === 'string') {
    return value.length > 0;
  } else if (typeof value === 'number') {
    return value !== 0;
  } else if (typeof value === 'object' && value) {
    return Object.values(value).some(Boolean);
  }

  return Boolean(value);
};

export const areSomeFiltersApplied = <T extends Record<string, any>>(
  filters: T
): boolean => Object.values(filters).some(isFilterApplied);

export const clearCacheAndReload = () => {
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });

  setTimeout(() => {
    window.location.reload();
  }, 500);
};

export const addPercentageLabel = (value: string | number) => `${value}%`;

export const formatAPR = (value: number | string) => formatNumber(value, 2);

export const determineChainTypeFromAddress = (
  address: string
): CosmosChainType => {
  if (address.startsWith('secret')) return 'SECRET';

  if (address.startsWith('cosmos')) return 'COSMOS';

  if (address.startsWith('lava')) return 'LAVA';

  return 'COSMOS';
};

export const getCosmosValidatorImageSrc = (address?: string) => {
  if (address) {
    const chainType = determineChainTypeFromAddress(address);

    if (chainType) {
      return replaceAllWithMap(URL.COSMOS_VALIDATOR_ICON, {
        '{validatorAddress}': address,
        '{chainType}': chainType.toLowerCase(),
      });
    }
  }
};
