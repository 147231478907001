import { ThunkDispatch } from 'redux-thunk';
import { verifyTOTPSetup, updateMFAPreference } from 'aws-amplify/auth';

import { handleError } from 'polli-commons-fe/utils/error';
import { showSuccess } from 'polli-commons-fe/utils/helpers';
import { NoDataPlaceholder } from 'polli-commons-fe/config/constants';
import {
  ChainType,
  CosmosChainType,
  CosmosChainTypeOptions,
} from 'polli-commons-fe/types';

import Messages from 'config/messages';
import { walletsApi } from 'store/api/wallets';
import { setMaxValidatorsExceeded } from 'store/slices/max-validators-exceeded';
import { setAlreadyLinkedWallets } from 'store/slices/already-linked-wallets-addresses';

export const percentage = (
  partialValue: string | number,
  totalValue: string | number
) => {
  return (100 * Number(partialValue)) / Number(totalValue);
};

export const handleData = <T, U>(
  data: T,
  handler: (value: Exclude<T, null | undefined>) => U,
  emptyDataPlaceholder: string | null = NoDataPlaceholder
) =>
  data || data === 0
    ? handler(data as Exclude<T, null | undefined>)
    : emptyDataPlaceholder;

export const composePagePath = (...paths: string[]) => paths.join('/');

export const setupTwoFactorAuthentication = async (
  code: string,
  onSuccess: () => void
) => {
  try {
    await verifyTOTPSetup({ code });
    await updateMFAPreference({ totp: 'PREFERRED' });
    showSuccess(Messages.TWO_FACTOR_ENABLED);
    onSuccess();
  } catch (error) {
    handleError(error, Messages.CODE_VERIFICATION_ERROR);
  }
};

export const isCosmosChainType = (
  chainType: ChainType
): chainType is CosmosChainType =>
  CosmosChainTypeOptions.includes(chainType as CosmosChainType);

export const isDefinedAndNotNull = <T>(
  value: T
): value is Exclude<T, null | undefined> =>
  value !== undefined && value !== null;

export const isWalletAlreadyLinkedError = (
  error: Record<string, any> | undefined
) =>
  error &&
  error.status === 409 &&
  error.data?.errorMessage === 'Wallet already linked to another account';

export const handleNewEthAddress = async (
  address: string | null,
  dispatch: ThunkDispatch<any, any, any>
) => {
  const walletsPromise = dispatch(walletsApi.endpoints.get.initiate());

  const { data: walletsData } = await walletsPromise;

  const walletAlreadySaved = walletsData?.some(
    ({ wallet }) => wallet.address === address
  );

  if (!walletAlreadySaved && address) {
    const savePromise = dispatch(
      walletsApi.endpoints.save.initiate({
        address,
        chainType: 'ETHEREUM',
      })
    );

    const { error } = await savePromise;

    if (isWalletAlreadyLinkedError(error)) {
      dispatch(setAlreadyLinkedWallets([address]));
      walletsPromise.unsubscribe();
      return { hasError: true };
    }

    if (error && (error as any)?.status === 422) {
      dispatch(setMaxValidatorsExceeded(true));
      walletsPromise.unsubscribe();
      return { hasError: true };
    }

    walletsPromise.unsubscribe();
    return { hasError: false };
  }

  walletsPromise.unsubscribe();
  return { hasError: false };
};
