import styled, { css } from 'styled-components';

import { Notification } from '../../types';

export const ActionRequiredContainer = styled.div<{ actionRequired: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  ${({ theme, actionRequired }) =>
    actionRequired
      ? css`
          color: ${theme.colors.red.additional.main};

          svg path {
            fill: ${theme.colors.red.additional.main};
          }
        `
      : css`
          color: ${theme.colors.success};

          svg path {
            fill: ${theme.colors.success};
          }
        `}
`;

export const SeverityBadge = styled.div<{ severity: Notification['severity'] }>`
  padding: 4px 12px 4px 12px;
  gap: 4px;
  border-radius: 34px;
  text-align: center;

  ${({ theme, severity }) => css`
    background-color: ${theme.config.notificationCenter.severityBadge
      .background[severity]};
  `}
`;

export const NotificationMessageContainer = styled.div`
  border-radius: 24px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.black};
`;
